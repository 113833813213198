import React from 'react'
import { graphql } from "gatsby"
import TextPage from '../templates/textPage'

const Contact = ({ data, location }) => <TextPage data={data.wordpressPage} location={location} />

export const pageQuery = graphql`
  query {
    wordpressPage(slug: {eq: "contact"}) {
      title
      content
    }
}
`
export default Contact